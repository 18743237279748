<template>
    <d-container fluid>
        <pageTitle title="Overzicht" subtitle="Merken en Modellen`"/>
        <d-row>
            <vCard :col="8" header="Merken" :fluid="true" :actions="cardActions">
                <vTable :fields="fields"
                        :actions="actions"
                        :data="data"
                        :searchKeys="['name']"/>
            </vCard>
            <vCard :col="4" header="Merken">
                <addMake @submit="eventAddMake"/>
                <editMakeModal ref="editMakeModal"/>
            </vCard>
        </d-row>
    </d-container>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'Overzicht',
    title: 'Merken en Modellen',
    components: {
        addMake: () => import('@/components/forms/make/addMake'),
        editMakeModal: () => import('../modals/editMakeModal')
    },
    data() {
        return {
            cardActions: [{
                icon: '<i class="material-icons">refresh</i>',
                fn: () => {
                    // this.data = null
                    // this.getMakes()
                    console.log(this.$goTo('modellen'))
                }
            }],
            actions: [
                {
                    icon: '<i class="material-icons">visibility</i>',
                    fn: (make) => {
                        this.$router.push({name: 'dashboard/inkoop/makeAndModel/modellen', params: {make_id: make.id}})
                    }
                },
                {
                    icon: '<i class="material-icons">edit</i>',
                    fn: (make) => {
                        this.$refs.editMakeModal.openHandler(make)
                    }
                }
            ],
            copyData: null,
            data: null,
            search: null,
            fields: [
                {
                    name: 'name',
                    title: 'Merken',
                    sortField: 'name'
                },
                'actions'
            ],
        }
    },
    created() {
        this.getMakes()
    },
    methods: {
        async getMakes() {
            try {
                let {data} = await this.$api.make.list()
                data.sort( function( a , b){
                    if(a.name > b.name) return 1;
                    if(a.name < b.name) return -1;
                    return 0;
                });
                this.data = data
            } catch (e) {
            }
        },
        eventAddMake(make) {
            this.data.push(make)
        },
    }
}
</script>

<style scoped>

</style>
